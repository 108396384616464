
export const sanitizeData = (jsonArray) => {
  const whitelistRegex = /^(true|false|[0-9a-zA-Z,. \-_]+)$/;
  const dataFields = ['Timestamp', 'Facility_id', 'Customer_id', 'Model_id' ,'Segment', 'ECL', 'PD12', 'D12', 'Default', 'Rating']
  const dataFieldsNotNull = ['Timestamp', 'Facility_id', 'Customer_id', 'Model_id' ,'Segment', 'ECL', 'PD12', 'D12']
  const filteredArray = [];
  const discardedArray = [];

  jsonArray.forEach((jsonObj, index) => {
    let isValid = true;

    dataFields.every( key =>  {
      if (!(Object.keys(jsonObj).includes(key))) {
        console.log('missing field ' + key + ' index ' + index)
        discardedArray.push(index);
        isValid = false;
        return isValid
      } else {
        return true
      }
    })

    if (isValid) {
      for (const key in jsonObj) {
        const value = jsonObj[key];
        if (!(key.match(whitelistRegex)) || !(String(value).match(whitelistRegex))) {
          console.log('regex ' + key + 'index ' + index)
          discardedArray.push(index);
          isValid = false;
          break;
        }

        if (value === undefined) {
          console.log('undefined ' + key + ' value: ' + value)
          discardedArray.push(index);
          isValid = false;
          break;
        }

        if (dataFieldsNotNull.includes(key)) {
          if (jsonObj[key] === null || jsonObj[key] === undefined) {
            console.log(key + ' null or undefined' + ' index ' + index)
            discardedArray.push(index);
            isValid = false;
            break;
          }
        }
      }
    }

    if (isValid) {
      filteredArray.push(jsonObj);
    }
  });
  return [filteredArray, discardedArray];
};

const findMinMaxTimestamp = (jsonArray) => {
  if (!jsonArray || jsonArray.length === 0) {
    return null;
  }
  const timestamps = jsonArray.map(obj => (obj.Timestamp)?.slice(0, 7));

  const uniqueYearMonths = [...new Set(timestamps)]; // Get unique yyyy-mm values
  const minYearMonth = uniqueYearMonths.reduce((min, current) => current < min ? current : min);
  const maxYearMonth = uniqueYearMonths.reduce((max, current) => current > max ? current : max);

  return [minYearMonth, maxYearMonth];
};


const determinFrequency = (jsonArray) => {
  if (!jsonArray || jsonArray.length === 0) {
    return null;
  }

  const convertDateStrToIntArray = (dateStr) => {
    const [yearStr, monthStr] = dateStr.split('-');

    const year = parseInt(yearStr);
    const month = parseInt(monthStr);

    return [year, month]
  }

  const calcTimeDiff = (a, b) => {
    return ((b[0] - a[0]) * 12) + (b[1] - a[1])
  }

  const timestamps = jsonArray.map(obj => (obj.Timestamp)?.slice(0, 7));
  const uniqueYearMonths = [...new Set(timestamps)];
  const sortedUniques = uniqueYearMonths.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

  let tempList = []
  for (let i = 0; i < sortedUniques.length - 1; i++) {
    const diff = calcTimeDiff(convertDateStrToIntArray(sortedUniques[i]), convertDateStrToIntArray(sortedUniques[i + 1]))
    tempList.push(diff)
  }

  const freqList = [...new Set(tempList)]

  const frequencies = []

  if (freqList.includes(12)) {
    frequencies.push('Yearly')
  }
  if (freqList.includes(6)) {
    frequencies.push('Half-yearly')
    frequencies.push('Yearly')
  }
  if (freqList.includes(3)) {
    frequencies.push('Quarterly')
    frequencies.push('Half-yearly')
    frequencies.push('Yearly')
  }
  if (freqList.includes(1)) {
    frequencies.push('Monthly')
    frequencies.push('Quarterly')
    frequencies.push('Half-yearly')
    frequencies.push('Yearly')
  }

  return frequencies
}

const determinSegmentation = (data) => {
  const segments = data.map(obj => (obj.Segment));
  const uniqueSegments = [...new Set(segments)];
  // uniqueSegments.push('Total')
  return uniqueSegments
}


//TODO: Check if input factors are present = keys after Rating 

export const splitDataForSettings = (data) => {

  const minMaxDates = findMinMaxTimestamp(data)
  const frequency = determinFrequency(data)
  const segmentation = determinSegmentation(data)

  return { 'minMaxDates': minMaxDates, 'frequency': frequency, 'segments': segmentation }
}

