import { RenderMenu } from "./RenderNavigation"
import { useNavigate, useLocation } from "react-router-dom";

const RenderHeader = () => {
  const navigate = useNavigate()
  const location = useLocation()

  let locationName = ''
  const url = location.pathname
  if (url.length > 1) {
    locationName = (url).substring(1)[0].toUpperCase() + (url).substring(1).slice(1)
  }


  const HeaderHome = () => {
    return (
      <div className="header-home">
        <div className="logo">
          <img onClick={() => { navigate("/home") }} src="/cr_logo_name_neg_rgb.svg" alt="CR Consulting" />
        </div>
      </div>
    )
  }

  const Header = (props) => {

    return (
      <div className="header">
        <img id='logo-img' onClick={() => { navigate("/home") }} src="/logo_neg.svg" alt="CR Consulting" />
        <h1 >{Object.values(props)[0]}</h1>
        <div ></div>
      </div>
    )
  }

  return (
    <section>
      {locationName ? <Header props={locationName} /> : <HeaderHome />}
      <div>
        <RenderMenu />
      </div>
    </section>
  )
}

export default RenderHeader