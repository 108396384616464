import Info from "../pages/Info";
import Account from "../pages/Account";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Valhalla from "../pages/Valhalla";
import SME from "../pages/SME";
import Missing from "../pages/Missing";
import Admin from "../pages/Admin";
import Unauthorized from "../pages/Unauthorized";

// TODO: Remove from frontend
const ROLES = {
  Admin: 101,
  CR: 102,
  User: 103,
  Valhalla: 201,
  SME: 202,
};

export const nav = [
  {
    path: "/",
    name: "",
    element: <Login />,
    isMenu: false,
    isPrivate: false,
    allowedRoles: [],
  },
  // {
  //   path: "/info",
  //   name: "Info",
  //   element: <Info />,
  //   isMenu: true,
  //   isPrivate: false,
  //   allowedRoles: [],
  // },
  {
    path: "/unauthorized",
    name: "Unauthorizied",
    element: <Unauthorized />,
    isMenu: false,
    isPrivate: false,
    allowedRoles: [],
  },
  {
    path: "*",
    name: "missing",
    element: <Missing />,
    isMenu: false,
    isPrivate: false,
    allowedRoles: [],
  },

  {
    path: "/home",
    name: "Home",
    element: <Home />,
    isMenu: true,
    isPrivate: true,
    allowedRoles: [ROLES.User],
  },


  {
    path: "/valhalla",
    name: "Valhalla",
    element: <Valhalla />,
    isMenu: true,
    isPrivate: true,
    allowedRoles: [ROLES.User],
  },

  {
    path: "/sme",
    name: "SME",
    element: <SME />,
    isMenu: true,
    isPrivate: true,
    allowedRoles: [ROLES.User],
  },

  {
    path: "/Admin",
    name: "Admin",
    element: <Admin />,
    isMenu: true,
    isPrivate: true,
    allowedRoles: [ROLES.Admin],
  },

  {
    path: "/account",
    name: "Account",
    element: <Account />,
    isMenu: true,
    isPrivate: true,
    allowedRoles: [ROLES.User],
  },
];
