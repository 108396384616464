import ReactSlider from 'react-slider'
import React, { useState } from 'react'
import './RAGLimits.css'
import { Grid } from '@mantine/core';

const RAGLimits = ({ inverted, defaultValues }) => {
  const [currentValue, setCurrentValue] = useState(defaultValues);

  const ragClassName = (inverted) => {
    return inverted ? 'customSlider' : 'customSlider-inverted'
  }
  return (
    <div className='rag-container'>
      <label htmlFor='rag-border' className='rag-label'>Set RAG Limits %</label>
      <div className='rag-border'></div>
      {!inverted ?
        <Grid className='rag-display' grow align='center'>
          <Grid.Col span={2} className='rag-display-red'><p>[0, {currentValue[0]})</p></Grid.Col>
          <Grid.Col span={2} className='rag-display-amber'><p>[{currentValue[0]}, {currentValue[1]})</p></Grid.Col>
          <Grid.Col span={2} className='rag-display-green'><p>[{currentValue[1]}, 100]</p></Grid.Col>
        </Grid>
        :
        <Grid className='rag-display' grow align='center'>
          <Grid.Col span={2} className='rag-display-green'><p>[0, {currentValue[0]})</p></Grid.Col>
          <Grid.Col span={2} className='rag-display-amber'><p>[{currentValue[0]}, {currentValue[1]})</p></Grid.Col>
          <Grid.Col span={2} className='rag-display-red'><p>[{currentValue[1]}, 100]</p></Grid.Col>
        </Grid>}
      <ReactSlider
        className={ragClassName(inverted)}
        trackClassName="customSlider-track"
        thumbClassName="customSlider-thumb"
        renderThumb={(props, state) => <div {...props}><div className='rag-tooltip'>{state.valueNow}</div></div>}
        min={0}
        max={100}
        step={1}
        value={currentValue}
        onChange={setCurrentValue}
        defaultValue={defaultValues}
      />
    </div>
  )
}

export default RAGLimits