import React from 'react'
import { IconHelp } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { Modal, ScrollArea, Group, Button, Text, Space } from '@mantine/core';

const InfoPop = ({ children, title, text }) => {
  const [opened, { open, close }] = useDisclosure(false);

  const handleOpen = (e) => {
    e.stopPropagation()
    open()
  }

  const handleClose = (e) => {
    e.stopPropagation()
    close()
  }

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div >
          {children}
        </div>
        <div style={{ marginLeft: '3px' }}>
          <IconHelp
            size={24}
            strokeWidth={1.5}
            color='#ff6a70'
            onClick={handleOpen}
          />
        </div>
      </div >

      <Modal.Root
        size="auto"
        opened={opened}
        onClose={handleClose}
        closeOnClickOutside={false}
        scrollAreaComponent={ScrollArea.Autosize}
        closeOnEscape={true}
      >
        <Modal.Overlay opacity={0.50} blur={3} />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title><h2>{title}</h2></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {text}
            </div>
            <Space h='md'></Space>
            <Group position="center">
              <Button onClick={handleClose}>Close</Button>
            </Group>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>



    </>
  )
}

export default InfoPop