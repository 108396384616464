import React, {useEffect, useState} from 'react'
import { Checkbox, Flex, Space, Text } from '@mantine/core';

const ChooseTests = (props) => {
  const [value, setValue] = useState(['psi', 'gini', 'gini_delta', 'acc_dob_test',
      'acc_dob_test_time', 'acc_pd_odr', 'acc_pd_odr_time', 'top_delta_ecl', 'top_delta_pd12']);
  const [psiFlag, setPsiFlag] = useState(false);
  const [giniFlag, setGiniFlag] = useState(false);
  const [giniDFlag, setGiniDFlag] = useState(false);
  const [accDobFlag, setAccDobFlag] = useState(false);
  const [accDobTimeFlag, setAccDobTimeFlag] = useState(false);
  const [accPDODRFlag, setAccPDORFlag] = useState(false);
  const [accPDODRTimeFlag, setAccPDORTimeFlag] = useState(false);
  const [topECLFlag, setTopECLFlag] = useState(false);
  const [topPD12Flag, setTopPD12Flag] = useState(false);

  // TODO: Set flags from props.testFlags to determine which tests are viable
  //   useEffect(() => {
  //       if (!props.hasDev?.length) {
  //         setGiniDFlag(true)
  //         if (!props.hasRisk) {
  //             setPsiFlag(true)
  //         }
  //       }
  //       if (!props.hasPro?.length) {
  //         setAccDobTimeFlag(true)
  //         setAccPDORTimeFlag(true)
  //       }
  //
  //   }, [props.hasDev[1], props.hasPro[1], props.hasRisk]);

  const handleCallback = (value) => {
    setValue(value)
    props.reciveChosenTests(value)
  }

  return (
    <>
      <Text fz='md'>
        Choose which tests should be included in the report
      </Text>
      <Text c='dimmed' fz='sm'>
        RAG Limits and thresholds can be set for the chosen tests in the next tab
      </Text>
      <Space h='md' />
      <Checkbox.Group
        value={value}
        onChange={handleCallback}
      >
        <Flex position='left' direction='column' gap="xs">

          {/*  TODO: Disable psi if development date not set*/}
          <Checkbox disabled={psiFlag} value={!psiFlag ? "psi" : null} label="PSI" description='Test the stability of input factor distributions between development and application data for the model.' />
          <Checkbox disabled={giniFlag} value="gini" label="GINI" description='Test the model`s PD12 outputs for their ability to distinguish between bad and good customers.' />
          <Checkbox disabled={giniDFlag} value={!giniDFlag ? "gini_delta" : null} label={`${String.fromCharCode(0x0394)}GINI`} description='Compare Gini values between development- and application data.' />
          <Checkbox disabled={accDobFlag} value="acc_dob_test" label="Jefferys Test - Current" description='Hypotheses test, which tests if observed default rate can be assumed equal to the average PD12 value for each Rating group in the current period.' />

          {/*  TODO: Disable acc_dob_test_time if development date not set*/}
          <Checkbox disabled={accDobTimeFlag} value={!accDobTimeFlag ? "acc_dob_test_time" : null} label="Jefferys Test - Over Time" description='Hypotheses test, which tests if observed default rate can be assumed equal to the average PD12 value for each Rating group for each period in selected application data.' />
          <Checkbox disabled={accPDODRFlag} value="acc_pd_odr" label="Relative Deviation - Current" description='Test the relative deviation between observed default rate and average PD12 value for each Rating group in the current period.' />

          {/*  TODO: Disable acc_pd_odr_time if development date not set*/}
          <Checkbox disabled={accPDODRTimeFlag} value={!accPDODRTimeFlag ? "acc_pd_odr_time" : null} label="Relative Deviation - Over Time" description='Test the relative deviation between observed default rate and average PD12 value for each Rating group for each period in selected application data.' />
          {/*<Checkbox disabled={topECLFlag} value="top_delta_ecl" label={`Top ${String.fromCharCode(0x0394)}ECL`} description='Get the highest ECL differences.' />*/}
          {/*<Checkbox disabled={topPD12Flag} value="top_delta_pd12" label={`Top ${String.fromCharCode(0x0394)}PD-12`} description='Get the highest PD12 differences. ' />*/}
        </Flex>
      </Checkbox.Group >
    </>

  )
}

export default ChooseTests