import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { Divider, Grid, Text, Title } from "@mantine/core";
import CRLoader from "../../animations/CRLoader";
import InfoPop from "./InfoPop";
import Plotly from "plotly.js/dist/plotly";
import Plot from 'react-plotly.js';
import "./ReportPreview.css";

const ReportPreview = (props) => {
  const axiosPrivate = useAxiosPrivate();
  const settings = props.settings;
  const data = props.data;
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);
  // const [pngUri, setPngUri] = useState([]);
  const [figs, setFigs] = useState([]);

  useEffect(() => {

    // props.reciveImg(pngUri);
    setReady(false);

  }, [props, props.active, ready]);

  useEffect(() => {
    const controller = new AbortController();

    const getResults = async () => {
      setLoading(true);

      try {
        const url = "/results";
        console.log(settings)
        const requestData = { data: data, settings: settings };
        console.log(requestData)
        await axiosPrivate.post(url, requestData, {
            signal: controller.signal,
          })
          .then((res) => {
            if (res.data.message === 'ok') {
              // setPngUri([])
              setFigs(res.data.figs)
              res.data.figs.forEach((plotData) => {
                const layout = {
                  height: plotData.fig.data[0].cells ? (plotData.fig.data[0].cells.values[0].length + 1) * 50 : 500,
                  width: 600,
                  autosize: false,
                  margin: plotData.fig.data[0].cells ? { l: 0, r: 0, b: 0, t: 10, pad: 4 } : { l: 15, r: 0, b: 20, t: 10, pad: 4 },
                  legend: { x: 1, xanchor: 'right', y: 1 }
                }
                const elem = document.createElement("div");
                return Plotly.newPlot(elem, plotData.fig.data, layout)
                  // .then((gd) => {
                  //   return Plotly.toImage(gd);
                  // })
                  // .then((dataURI) => {
                  //   setPngUri((pngUri) => {
                  //     return [...pngUri, dataURI];
                  //   });
              //     });
              });
            }
          });
        setReady(true);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    getResults();

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Grid>
      <Grid.Col span={12}>
        <Divider />
      </Grid.Col>
      <Grid.Col span={12}>
        <InfoPop title={"Data presentation preview - Help?"} text={""}>
          <Title order={2}>Data presentation preview</Title>
        </InfoPop>
        <Text fz="md" c="dimmed">
          Scroll to preview tables and graphs. Click next to the button below to
          generate and download the .docx report. If you need to change any
          settings, click back and try again.
        </Text>
      </Grid.Col>
      {loading ? (
        <CRLoader />
      ) : (
        <Grid>
          {figs.map((plotData, i) => (
            <Grid.Col key={i}>
              <Text fz="xl" fw={700} ta="center">
                {plotData.title}
              </Text>
              <Plot
                data={plotData.fig.data}
                style={{ width: '100%' }}
                useResizeHandler={true}
                config={{ responsive: true }}
                layout={{
                  height: plotData.fig.data[0].cells ? (plotData.fig.data[0].cells.values[0].length + 1) * 30 : 500,
                  autosize: true,
                  margin: plotData.fig.data[0].cells ? { l: 0, r: 0, b: 0, t: 10, pad: 4 } : { l: 15, r: 0, b: 20, t: 10, pad: 4 },
                  legend: { x: 1, xanchor: 'right', y: 1 }
                }}
              />
            </Grid.Col>
          ))}

          {/* {pngUri.map((png, i) => (
            <Grid.Col key={i}>
              <Text tt="Capitalize" fz="xl" fw={700} ta="center">
                { }
              </Text>
              <img src={png} alt={"missing plot"} width='100%' />
            </Grid.Col>
          ))} */}
        </Grid>
      )}

      <Grid.Col span={12}>
        <Divider />
      </Grid.Col>
    </Grid>
  );
};

export default ReportPreview;
