import { Navigate, useLocation, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import jwt_decode from 'jwt-decode'

export const getRoles = (auth) => {

  const decoded = auth?.accessToken ? jwt_decode(auth.accessToken) : undefined
  const roles = decoded?.roles || []

  return roles
}

const RequireAuth = ({ allowedRoles }) => {
  const { auth } = useAuth()
  const location = useLocation()

  // const decoded = auth?.accessToken ? jwt_decode(auth.accessToken) : undefined
  // const roles = decoded?.roles || []
  const roles = getRoles(auth)

  return (
    roles.find(role => allowedRoles?.includes(role))
      ? <Outlet />
      : auth?.user
        ? <Navigate to='/unauthorized' state={{ from: location }} replace />
        : <Navigate to='/' state={{ from: location }} replace />
  )
}

export default RequireAuth

// const RequireAuth = ({ allowedRoles }) => {
//   const { auth } = useAuth()
//   const location = useLocation()

//   return (
//     auth?.roles?.find(role => allowedRoles?.includes(role))
//       ? <Outlet />
//       : auth?.user
//         ? <Navigate to='/unauthorized' state={{ from: location }} replace />
//         : <Navigate to='/login' state={{ from: location }} replace />
//   )
// }

// export default RequireAuth
