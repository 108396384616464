import Lottie from "lottie-react";
import cr_loading from './cr_loading'

const CRLoader = () => {
  const style = {
    width: '15%',
  }

  return (
    <div className="d-flex  justify-content-center w-100">
      <Lottie
        style={style}
        animationData={cr_loading}
      />
    </div>
  )
};

export default CRLoader;