import React, { useState, useEffect } from 'react';
import {Grid, Select, Slider, Accordion, Text, Chip, Group, Divider, TextInput} from '@mantine/core';
import { MonthPickerInput } from '@mantine/dates';
import { IconCalendar } from '@tabler/icons-react';

import HorizontalLineWithMarkers from './HorizontalLineWithMarkers';
import RAGLimits from './RAGLimits';
import ChooseTests from './ChooseTests';
import InfoPop from './InfoPop';
import { addOneMonth, subtractOneMonth } from './dateUtils';
import DeltaGiniRAG from './GiniRAG';

const Settings = (props) => {
  const [modelName, setModelName] = useState('')
  const currentTimestamp = props.data['minMaxDates'][1];
  const [cutoffDateDev, setCutoffDateDev] = useState([]);
  const [cutoffDatePro, setCutoffDatePro] = useState([]);
  const frequencies = props.data['frequency']
  const [frequency, setFrequency] = useState();
  const segments = props.data['segments']
  const [chosenSegment, setChosenSegment] = useState(segments);
  const [chosenTests, setChosenTests] = useState(['psi', 'gini', 'gini_delta', 'acc_dob_test', 'acc_dob_test_time', 'acc_pd_odr', 'acc_pd_odr_time',
    // 'top_delta_ecl', 'top_delta_pd12'
  ]);
  const [psiRag, setPsiRag] = useState([10, 25]);
  const [giniRag, setGiniRag] = useState([40, 70]);
  const [giniDeltaRag, setGiniDelta] = useState([-10, 0]);
  const [accDobTest, setAccDobTest] = useState([5, 10]);
  const [accPd_odr, setAccPd_odr] = useState([5, 10]);
  const [topDeltaECL, setTopDeltaECL] = useState(10);
  const [topDeltaPD12, setTopDeltaPD12] = useState(10);
  const whitelistRegex = /^[a-zA-Z0-9\-_\.\,:\s;]+$/;
  const Timeline = () => {
    return (
      <HorizontalLineWithMarkers
        currentTimestamp={parseInt(currentTimestamp.substring(5, 7))}
        frequency={frequency}
      />
    )
  }

  useEffect(() => {
    const settingsObject = {
      'modelName': modelName,
      'currentTimestamp': currentTimestamp.slice(0, 7),
      'cutoffDateDev': cutoffDateDev[1] ?
        [addOneMonth(cutoffDateDev[0]).toISOString().slice(0, 7), addOneMonth(cutoffDateDev[1]).toISOString().slice(0, 7)]
        : [],
      'cutoffDatePro': cutoffDatePro[1] ?
        [addOneMonth(cutoffDatePro[0]).toISOString().slice(0, 7), addOneMonth(cutoffDatePro[1]).toISOString().slice(0, 7)]
        : [],
      'frequency': frequency ? frequency.toLowerCase() : 'yearly',
      'segments': segments,
      'chosenSegment': chosenSegment,
      'chosenTests': chosenTests,
      'psiRag': psiRag,
      'giniRag': giniRag,
      'giniDeltaRag': giniDeltaRag,
      'accDobTest': accDobTest,
      'accPd_odr': accPd_odr,
      'topDeltaECL': topDeltaECL,
      'topDeltaPD12': topDeltaPD12,
    }
    props.reciveSettings(settingsObject)
  }, [accDobTest, modelName, accPd_odr, chosenSegment, chosenTests, currentTimestamp, cutoffDateDev, cutoffDatePro, frequency, giniDeltaRag, giniRag, props.active, psiRag, segments, topDeltaECL, topDeltaPD12])

  const callbackChosenTests = (fromChild) => {
    setChosenTests(fromChild)
  }

  const marks = [
    { value: 0, label: '0' },
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 30, label: '30' },
    { value: 40, label: '40' },
    { value: 50, label: '50' },
  ];

  function filterString(inputString) {
    // Use the regular expression to filter the characters
    return inputString.split('').filter(char => whitelistRegex.test(char)).join('');
  }

  return (
    <>
      <Grid >
        <Grid.Col span={4}>
          <TextInput
              size="lg"
              label="Model Name"
              description="Type the model name here. What you type will be displayed as a headline ine the report."
              placeholder="Ex. PD..."
              value={modelName}
              onChange={(event) => {
                  setModelName(filterString(event.currentTarget.value))
              }}
          />
        </Grid.Col>
        <Grid.Col span={4}></Grid.Col>
        <Grid.Col span={4}>
          <Text size='md' ta='right'>
            On this page you can specify settings for you model. Your choices will be reflected in the report.
          </Text>
        </Grid.Col>
      </Grid>
      <Accordion variant="contained" multiple='true' defaultValue='timestamps'>
        <Accordion.Item value='timestamps'>
          <Accordion.Control>
            <InfoPop
              title={'Timestamps'}
              text={
                <div>
                  <Text size="lg">Development Data:</Text>
                  <Text>If the uploaded data contains the data used for developing the model, then specify that part of the data by setting the cut-off Date points. 'If the data does not include development data, then leave blank.</Text>
                  <br/>
                  <Text size="lg">Production Data:</Text>
                  <Text>If the uploaded data contains previous data used in production, then it is possible to include that in the report, by specifying a time interval for that production data period.</Text>
                  <br/>
                  <Text size="lg">Frequency:</Text>
                  <Text>Choose the timestamp frequency for the chosen production data period. For example, if the production data period is in monthly data, but only the quarterly data is of interest, choose Frequency=”Quarterly”</Text>
                  <br/>
                  <Text size="lg">Current timestamp:</Text>
                  <Text>Current timestamp is the newest timestamp in the uploaded data and should correspond to the latest available production data. It is data from the Current timestamp that will be validated. </Text>
                </div>
              }
              children={<h5>Timestamps</h5>}
            />
          </Accordion.Control>
          <Accordion.Panel>
            <Grid grow>
              <Grid.Col span={4}>
                <MonthPickerInput
                  icon={<IconCalendar size={18} stroke={1.5} />}
                  type='range'
                  label='Cut-off Date - Development Data'
                  description='Choose the last month to include in development data'
                  valueFormat="YYYY MMM"
                  placeholder="Pick a month"
                  allowSingleDateInRange
                  minDate={props.data['minMaxDates'][0]}
                  maxDate={subtractOneMonth(currentTimestamp)}
                  value={cutoffDateDev}
                  onChange={setCutoffDateDev}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <MonthPickerInput
                  icon={<IconCalendar size={18} stroke={1.5} />}
                  type='range'
                  label='Cut-off Date - Production Data'
                  description='Choose the first month to include in production data'
                  valueFormat="YYYY MMM"
                  allowSingleDateInRange
                  disabled={cutoffDateDev[1] === null ? true : false}
                  placeholder="Set cut-off for development first"
                  minDate={cutoffDateDev[1] ? addOneMonth(cutoffDateDev[1]) : props.data['minMaxDates'][0]}
                  maxDate={subtractOneMonth(currentTimestamp)}
                  value={cutoffDatePro}
                  onChange={setCutoffDatePro}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <Select
                  label="Frequency"
                  description='Choose with which frequency the data should be compared'
                  placeholder="Pick one"
                  clearable
                  nothingFound="No options"
                  radius="xs"
                  maxDropdownHeight={280}
                  data={frequencies}
                  value={frequency}
                  onChange={setFrequency}
                />
              </Grid.Col>
              <Grid.Col span={8} ><Text>Current time period:</Text></Grid.Col>
              <Grid.Col span={4} >Current timestamp: <span style={{ color: '#ff6a70' }}>{currentTimestamp}</span></Grid.Col>
              <Grid.Col span={12}>
                {frequency ? <Timeline /> : <div />}
              </Grid.Col>
              <Grid.Col span={12}>
              </Grid.Col>
            </Grid>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="segmentation">
          <Accordion.Control>
            <InfoPop
              title={'Segmentation '}
              text={
                <div>
                  {/*<Text size="lg">Headline:</Text>*/}
                  <Text>Choose which segments to include in the report. If only one unique segment is observed in the uploaded data, then that segment is mandatory.</Text>
                  <br/>
                </div>
              }
              children={<h5>Segmentation</h5>}
            />
          </Accordion.Control>
          <Accordion.Panel>
            <Grid grow >
              <Grid.Col span={12} >
                <Text>Choose segment(s)</Text>
              </Grid.Col>
              <Grid.Col span={12} >
                <Chip.Group multiple={true} value={chosenSegment} onChange={setChosenSegment}>
                  <Group position="center" spacing="xl">
                    {segments?.map((segment, index) => {
                      return (
                        <Chip size="md" radius="sm" key={index} value={segment}>{segment}</Chip>
                      )
                    })}
                  </Group>
                </Chip.Group>
              </Grid.Col>
            </Grid>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value='tests'>
          <Accordion.Control>
            <InfoPop
              title={'Tests - Help?'}
              text={''}
              children={<h5>Tests</h5>}
            />
          </Accordion.Control>
          <Accordion.Panel >
            <ChooseTests reciveChosenTests={callbackChosenTests} hasDev={cutoffDateDev} hasPro={cutoffDatePro} hasRisk={props.hasRiskFactors}/>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="threshold">
          <Accordion.Control>
            <InfoPop
              title={'Set the threshold for the RAG evaluations of the test'}
              text={<div>
                <Text size="xl">The high-level interpretation is as follows:</Text>
                <Text>Green:	Test passed</Text>
                <Text>Amber:	Test passed, with identified weaknesses</Text>
                <Text>Red:	    Test not passed</Text>
                <br/>
                <Text size="xl">Default settings is as follows:</Text>
                <Text td={"underline"} >PSI:</Text>
                <Text>Green: [0, 10), Amber: [10, 25), Red: [25, 100)</Text>
                <br/>
                <Text td={"underline"} >GINI:</Text>
                <Text>Red: [0, 40), Amber: [40, 70), Green: [70, 100)</Text>
                <br/>
                <Text td={"underline"} >ΔGINI:</Text>
                <Text>Red: [-200, 10), Amber: [10, 0), Green: [0, 50)</Text>
                <br/>
                <Text td={"underline"} >Jeffreys Test:</Text>
                <Text>Red: [-0, 5), Amber: [5, 10), Green: [0, 50)</Text>
                <br/>
                <Text td={"underline"} >Relative Deviation:</Text>
                <Text>Green: [0, 5), Amber: [5, 10), Red: [10, inf)</Text>
                <br/>
              </div>}
              children={<h5>Threshold</h5>}
            />
          </Accordion.Control>
          <Accordion.Panel>
            <Grid grow >
              {chosenTests?.includes('psi') ?
                <Grid.Col span={6}>
                  <Text>PSI</Text>
                  <RAGLimits inverted={true} defaultValues={psiRag} onChange={setPsiRag} />
                </Grid.Col>
                : <></>}
              {chosenTests?.includes('gini') ?
                <Grid.Col span={6}>
                  <Text>GINI</Text>
                  <RAGLimits inverted={false} defaultValues={giniRag} onChange={setGiniRag} />
                </Grid.Col>
                : <></>}
              {chosenTests?.includes('gini_delta') ?
                <Grid.Col span={6}>
                  {String.fromCharCode(0x0394)}GINI
                  <DeltaGiniRAG inverted={false} defaultValues={giniDeltaRag} onChange={setGiniDelta} />
                </Grid.Col>
                : <></>}
              {chosenTests?.includes('acc_dob_test') || chosenTests?.includes('acc_dob_test_time') ?
                <Grid.Col span={6}>
                  Jeffreys Test
                  <RAGLimits inverted={false} defaultValues={accDobTest} onChange={setAccDobTest} />
                </Grid.Col>
                : <></>}
              {chosenTests?.includes('acc_pd_odr') || chosenTests?.includes('acc_pd_odr_time') ?
                <Grid.Col span={6}>
                  Relative Deviation
                  <RAGLimits inverted={true} defaultValues={accPd_odr} onChange={setAccPd_odr} />
                </Grid.Col>
                : <></>}
              <Grid.Col span={6}></Grid.Col>
              <Grid.Col span={12}>
                <Divider />
              </Grid.Col>
              {chosenTests?.includes('top_delta_ecl') ?
                <Grid.Col span={6}>
                  Top {String.fromCharCode(0x0394)}ECL
                  <Slider
                    color="dark"
                    size="xs"
                    radius="xs"
                    value={topDeltaECL}
                    onChange={setTopDeltaECL}
                    min={0}
                    max={50}
                    step={5}
                    marks={marks}
                  />
                </Grid.Col>
                : <></>}
              {chosenTests?.includes('top_delta_pd12') ?
                <Grid.Col span={6}>
                  Top {String.fromCharCode(0x0394)}PD12
                  <Slider
                    color="dark"
                    size="xs"
                    radius="xs"
                    value={topDeltaPD12}
                    onChange={setTopDeltaPD12}
                    min={0}
                    max={50}
                    step={5}
                    marks={marks}
                  />
                </Grid.Col>
                : <></>}
              <Grid.Col span={12}>
              </Grid.Col>
            </Grid>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default Settings;

