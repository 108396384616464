import { Group, Text, useMantineTheme, rem, Container, Grid, Divider } from '@mantine/core';
import { IconUpload, IconPhoto, IconX } from '@tabler/icons-react';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import Papa from 'papaparse'
import { useState, useEffect } from 'react';

import CRLoader from '../../animations/CRLoader';
import { sanitizeData } from './sanitizeData'

const DataUpload = (props) => {
  const [data, setData] = useState()
  const [coruptData, setCoruptData] = useState()
  const theme = useMantineTheme();

  useEffect(() => {
    if (data) {
      props.reciveData(data)
    }
    if (coruptData) {
      props.reciveCoruptData(coruptData)
    }
  }, [coruptData, data, props])


  const handleDrop = (file) => {
    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      // TODO: Gem header for sig og ændre header navne i json til integer. Husk at map tilbage med dict
      const csv = Papa.parse(target.result, { header: true, dynamicTyping: true });
      const parsedData = csv?.data;
      const sanitizedData = sanitizeData(parsedData)
      setCoruptData(sanitizedData[1]);
      setData(sanitizedData[0]);
    };
    reader.readAsText(file[0]);
  };

  const Drop = () => {
    return (
      <Dropzone
        onDrop={file => { handleDrop(file) }}
        onReject={(file) => console.log('rejected files', file)}
        accept={{ 'text/csv': ['.csv'] }}
      >
        <Group position="center" spacing="xl" style={{ minHeight: rem(220), pointerEvents: 'none' }}>
          <Dropzone.Accept>
            <IconUpload
              size={56}
              stroke={1.5}
              color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX
              size={56}
              stroke={1.5}
              color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
            />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconPhoto
              size={56}
              stroke={1.5} />
          </Dropzone.Idle>

          <div>
            <Text size="xl" inline>
              Drag file here or click to browse local system
            </Text>
            <Text size="sm" color="dimmed" inline mt={7}>
              Accepted filetype: .csv.
            </Text>
          </div>
        </Group>
      </Dropzone>
    )
  }


  return (
    <Container >
      <Drop />
    </Container>
  );
}

export default DataUpload