import React from 'react';
import './HorizontalLineWithMarkers.css'; // Make sure to adjust the CSS as needed

const HorizontalLineWithMarkers = ({ currentTimestamp, frequency }) => {
  const monthLabels = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  const numMarkers = monthLabels.length;
  const markerSpacing = 100 / (numMarkers - 1);

  const markers = monthLabels.map((month, index) => {
    let color = 'black'
    if (currentTimestamp - 1 == index) {
      color = '#ff6a70'
    }
    const leftPosition = `${index * markerSpacing}%`;
    return (
      <div key={index} className="marker" style={{ left: leftPosition }}>
        <span className="month-label" style={{ color: color }}>{month}</span>
      </div>
    );
  });

  const topMarkers = (Frequency) => {
    let iterLength = []
    let letter = ''
    let num = []
    if (Frequency === 'Quarterly') {
      iterLength = [2, 5, 8, 11]
      num = [1, 2, 3, 4]
      letter = 'Q'
    } else if (Frequency === 'Half-yearly') {
      iterLength = [5, 11]
      letter = 'H'
      num = [1, 2]
    } else if (Frequency === 'Yearly') {
      iterLength = [11]
      letter = 'Y'
    }

    const div = iterLength.map((index, num) => {
      const leftPosition = `${index * markerSpacing}%`;
      num += 1
      return (
        <div key={index} className="top-marker" style={{ left: leftPosition }}>
          <span className="top-marker-label">{`${letter + num}`}</span>
        </div>
      );
    })

    return div
  };

  return (
    <div className="horizontal-line-with-markers">
      <div className="top-container">{topMarkers(frequency)}</div>
      <div className="line" />
      <div className="markers-container">{markers}</div>
    </div>
  );
};

export default HorizontalLineWithMarkers;
