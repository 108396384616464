import React, {useState} from 'react'
import {saveAs} from 'file-saver'
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {Button} from "@mantine/core";
import CRLoader from "../../animations/CRLoader";
const Report = (props) => {
    const [loading, setLoading] = useState(false)
    const axiosPrivate = useAxiosPrivate();

    const getReport = async () => {
        try {
            const url = "/report";
            const requestData = {data: props.data, settings: props.settings};
            setLoading(true)
            const response = await axiosPrivate.post(url, requestData, {
                    responseType: 'arraybuffer',
                })
            const report = new Blob([response.data])
            saveAs(report, 'report.docx')
            setLoading(false)
        } catch (err) {
            console.log(err);
        }
    }
    return (
        <div>
            {loading ? <CRLoader/> : <Button
                onClick={() => getReport()}
            >Download report</Button>}
        </div>
    )
}
export default Report