import React from 'react'
import { Outlet } from 'react-router-dom'
import RenderHeader from './Header'
import Footer from './Footer'

const Layout = () => {
  return (
    <main className='App'>
      <RenderHeader />
      <Outlet />
      <Footer />
    </main>
  )
}

export default Layout