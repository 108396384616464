const Home = () => {

  return (
    <div className="page">
      <h2>Home page</h2>
      <p>This is the text for the home page</p>
    </div>
  )
}

export default Home