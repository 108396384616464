import Users from "./Users"

const Admin = () => {

  return (
    <div className="page">
      <h2>Admin</h2>
      <p>This is the Admin area</p>
      <br />
      <Users />
      <br />
    </div>
  )
}

export default Admin