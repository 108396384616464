import useAuth from "../../hooks/useAuth"

const Account = () => {
  const { auth } = useAuth()
  const username = auth?.user
  return (
    <div className="page">
      <h2>Your Account</h2>
      <p>Username: {username}</p>
    </div>
  )
}

export default Account