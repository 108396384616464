import React from 'react';
import { Table } from '@mantine/core';
import './Table.css'

const TableComponent = ({ data }) => {
  // Slice the array to get the first five objects

  const displayedData = data.length > 20 ? data.slice(0, 5) : data;
  console.log(displayedData)

  const tableHeaders = displayedData.length > 0 ? Object.keys(displayedData[0]) : [];

  return (
    <div className='table-div'>
      <Table striped highlightOnHover withBorder withColumnBorders>
        <thead>
          <tr>
            {tableHeaders.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {displayedData.map((item, index) => (
            <tr key={index}>
              {tableHeaders.map((header, index) => (
                <td key={index}>{item[header]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TableComponent;
