import React from 'react';
import ReactDOM from "react-dom/client";
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import App from './App';
import {AuthProvider} from './context/AuthProvider';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {MantineProvider} from '@mantine/core';
import {disableReactDevTools} from '@fvilers/disable-react-devtools'
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";

if (process.env.NODE_ENV === 'production') {
    disableReactDevTools();
}

// Default cr-blue.5
const cr_blue = ["#28454E", "#20414B", "#183D49", "#103A48", "#083848", "#003749", "#072E3B", "#0B2730", "#0D2228", "#0E1D22"]
// Default cr-turk.4
const cr_turk = ["#D2E4E7", "#B3D7DD", "#93CFD9", "#70CCDB", "#48CFE5", "#40BBCF", "#3FA4B5", "#448C98", "#447981", "#43696F"]
// Default cr-red.3
const cr_red = ["#F8E9E9", "#F2C3C5", "#F4999D", "#FF6A70", "#EC585E", "#D84D52", "#C34449", "#A7464A", "#8E484A", "#794749"]
// Default cr-beige.3
const cr_beige = ["#FEFDFD", "#FAF8F7", "#F6F3F1", "#F2EEEB", "#EEE9E5", "#EBE4E0", "#E7E0DA", "#E3DBD5", "#E0D6CF", "#DDD2CA"]
// Default cr-black.4
const cr_black = ["#2A2A2A", "#272727", "#232323", "#202020", "#1D1D1D", "#1A1A1A", "#171717", "#151515", "#131313", "#111111"]
// Default cr-white.0
const cr_white = ["#FFFFFF", '#FCFCFC', '#FAFAFA', '#F7F7F7', "#E6E6E6", "#CFCFCF", "#BABABA", "#A7A7A7", "#979797", "#888888"]


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <BrowserRouter>
        <AuthProvider>
            <Routes>
                <Route path="/*" element={
                    <MantineProvider
                        theme={{
                            // Override any other properties from default theme
                            fontFamily: 'Barlow',
                            fontFamilyMonospace: 'Barlow',
                            headings: {fontFamily: 'Barlow'},
                            spacing: {xs: '1rem', sm: '1.2rem', md: '1.8rem', lg: '2.2rem', xl: '2.8rem'},
                            colors: {
                                'cr-blue': cr_blue,
                                'cr-red': cr_red,
                                'cr-turk': cr_turk,
                                'cr-beige': cr_beige,
                                'cr-black': cr_black,
                                'cr-white': cr_white,
                            },
                            primaryColor: 'cr-blue',
                            primaryShade: 5,
                        }}
                    >
                        <DevSupport ComponentPreviews={ComponentPreviews}
                                    useInitialHook={useInitial}
                        >
                            <App/>
                        </DevSupport>
                    </MantineProvider>
                }/>
            </Routes>
        </AuthProvider>
    </BrowserRouter>
);