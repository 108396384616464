import axios from '../api/axios'
import useAuth from './useAuth'

const useRefreshToken = () => {
  const { setAuth } = useAuth()

  const refresh = async () => {

    try {
      const response = await axios.get('/refresh', {
        withCredentials: true
      })

      setAuth(prev => {
        return { ...prev, accessToken: response.data.access_token }
      })
      return response.data.access_token
    } catch (error) {
      console.log(error)
    }
  }

  return refresh
}

export default useRefreshToken