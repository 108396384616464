import './App.css';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/structure/Layout';
import Login from './components/pages/Login'
import Account from './components/pages/Account';
import Admin from './components/pages/Admin';
import Home from './components/pages/Home';
// import Info from './components/pages/Info';
import Valhalla from './components/pages/Valhalla';
import SME from './components/pages/SME';
import Missing from './components/pages/Missing';
import Unauthorized from './components/pages/Unauthorized';
import RequireAuth from './components/RequireAuth';

// TODO: Remove from frontend
const ROLES = {
  'Admin': 101,
  'CR': 102,
  'User': 103,
  'Valhalla': 201,
  'SME': 202,
}

function App() {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        {/* public routes */}
        <Route path='/' element={<Login />} />
        {/* <Route path='info' element={<Info />} /> */}
        <Route path='unauthorized' element={<Unauthorized />} />

        {/* protected routes */}

        <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
          <Route path='/home' element={<Home />} />
          <Route path='account' element={<Account />} />

          <Route element={<RequireAuth allowedRoles={[ROLES.Valhalla]} />}>
            <Route path='valhalla' element={<Valhalla />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.SME]} />}>
            <Route path='/sme' element={<SME />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path='/admin' element={<Admin />} />
          </Route>
        </Route>

        {/* catch all */}
        <Route path='*' element={<Missing />} />

      </Route>
    </Routes>

  );
}

export default App;
