import { useState } from "react";
import {
  Anchor,
  Button,
  Container,
  Divider,
  Grid,
  Group,
  List,
  Modal,
  Space,
  Stepper,
  Table,
  Text,
} from "@mantine/core";
import { IconFileUpload, IconReport, IconSettings } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";

import CRLoader from "../animations/CRLoader";
import DataUpload from "./valhalla/DataUpload";
import Settings from "./valhalla/Settings";
import TableComponent from "./valhalla/TableComponent";
import InfoPop from "./valhalla/InfoPop";
import ReportPreview from "./valhalla/ReportPreview";
import Report from "./valhalla/Report";
import { splitDataForSettings } from "./valhalla/sanitizeData";
import settings from "./valhalla/Settings";

const Valhalla = () => {
  const [active, setActive] = useState(0);
  const nextStep = () => {
    setActive((current) => (current < 3 ? current + 1 : current));
    scrollToTop();
    const checkForRisk = () => {
      const dataFields = ['Timestamp', 'Facility_id', 'Customer_id', 'Model_id' ,'Segment', 'ECL', 'PD12', 'D12', 'Default', 'Rating', 'Stage']
      let flag = false
      Object.keys(data[0]).forEach(key => {
        if (!(dataFields.includes(key))) {
          flag = true
        }
      })
      setHasRisk(flag)
    }
    checkForRisk()
  };
  const prevStep = () => {
    setActive((current) => (current > 0 ? current - 1 : current));
    scrollToTop();
  };
  const [opened, { open, close }] = useDisclosure(false);
  const [loading, setLoading] = useState(false);
  const [dataUploaded, setDataUploaded] = useState(false);
  const [settingsData, setSettingsData] = useState();
  const [data, setData] = useState();
  const [filteredData, setFilteredData] = useState();
  const [coruptData, setCoruptData] = useState();
  const [settings, setSettings] = useState({});
  const [hasRisk, setHasRisk] = useState(false);
  // const [img, setImg] = useState();
  // const [imgSizes, setImgSizes] = useState();

  const handleFilterData = () => {
    const quart = [3, 6, 9, 12];
    const halfY = [6, 12];
    const year = [12];

    if (settings.cutoffDatePro[1]) {
      const filterData = (input, start, end) => {
        const tempData = [];

        input.forEach((row) => {
          const time = row.Timestamp.slice(0, 7);
          if (time >= start && time <= end) {
            tempData.push(row);
          }
        });
        return tempData;
      };

      const current = filterData(
        data,
        settings.currentTimestamp,
        settings.currentTimestamp,
      );
      const devData = settings.cutoffDateDev[1] ? filterData(
        data,
        settings.cutoffDateDev[0],
        settings.cutoffDateDev[1],
      ) : [];
      const proData = filterData(
        data,
        settings.cutoffDatePro[0],
        settings.cutoffDatePro[1],
      );

      if (
        settingsData.frequency.includes("Monthly") &&
        settings.frequency === "Quarterly"
      ) {
        const filtered = [];
        proData.forEach((row) => {
          const mon = parseInt(row.Timestamp.split("-")[1]);
          if (quart.includes(mon)) {
            filtered.push(row);
          }
        });
        setFilteredData([...devData, ...filtered, ...current]);

      } else if (
        settingsData.frequency.includes("Quarterly") &&
        settings.frequency === "Half-yearly"
      ) {
        const filtered = [];
        proData.forEach((row) => {
          const mon = parseInt(row.Timestamp.split("-")[1]);
          if (halfY.includes(mon)) {
            filtered.push(row);
          }
        });
        setFilteredData([...devData, ...filtered, ...current]);
      } else if (
        settingsData.frequency.includes("Half-yearly") &&
        settings.frequency === "Yearly"
      ) {
        const filtered = [];
        proData.forEach((row) => {
          const mon = parseInt(row.Timestamp.split("-")[1]);
          if (year.includes(mon)) {
            filtered.push(row);
          }
        });
        setFilteredData([...devData, ...filtered, ...current]);
      } else {
        setFilteredData([...devData, ...proData, ...current]);
      }
    } else {
      setFilteredData(data)
    }
    nextStep();
  };

  const callbackDataUpload = (fileFromChild) => {
    setSettingsData(splitDataForSettings(fileFromChild));
    setData(fileFromChild);
    setDataUploaded(true);
  };

  const callbackCoruptData = (fileFromChild) => {
    setCoruptData(fileFromChild);
  };

  const callbackSettings = (settingsFromChild) => {
    setSettings(settingsFromChild);
  };

  const handleCancel = () => {
    setDataUploaded(false);
  };

  const DataUploaded = () => {
    return (
      <Grid>
        <Grid.Col span={10}>
          <InfoPop
            title={"Data preview - Help?"}
            text={
              "Data with characters not of the type number, letter, period, comma, hyphen or underscore has been filterd out. Rows with no timestamp has also been filtered out."
            }
            children={<h2>Preview</h2>}
          />

          <p>
            This is a preview of the header with the first five rows from the
            uploaded dataset. Please check that data is as expected.
          </p>
        </Grid.Col>
        <Grid.Col span={2}>
          <Anchor
            color="cr-red.4"
            component="button"
            type="button"
            onClick={open}
            size={"sm"}
          >
            anomalies
          </Anchor>
          <Modal opened={opened} onClose={close} title="Data row anomalies">
            <Text>
              The following row(s) from the uploaded data contains one or more
              anomalies and are therefore excluded from the dataset:
            </Text>
            <Space h="xs"></Space>
            <List size="sm" withPadding type="unordered">
              {coruptData.map((row) => (
                <List.Item key={row}>nr. {row}</List.Item>
              ))}
            </List>
          </Modal>
        </Grid.Col>
        <Grid.Col span={12}>
          <TableComponent data={data} />
        </Grid.Col>
      </Grid>
    );
  };

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  // const callbackImg = (img) => {
  //   const sizes = img.map((uri) => {
  //     let img = document.createElement('img');
  //     img.src = uri;
  //     let realWidth = img.naturalWidth;
  //     let realHeight = img.naturalHeight;
  //
  //     return [realWidth, realHeight]
  //   })
  //   setImg(img);
  //   setImgSizes(sizes)
  // };

  return (
    <div className="page">
      <Container className="container-settings">
        <Stepper active={active} onStepClick={setActive} radius="sm" size="xl">
          <Stepper.Step icon={<IconFileUpload size={28} />} label="Upload data">
            <Grid>
              <Grid.Col span={12}>
                <Divider />
              </Grid.Col>
              <Grid.Col span={12}>
                {dataUploaded ? (
                  <DataUploaded />
                ) : (
                  <Grid>
                    <Grid.Col span={12}>
                      <Text>To upload your data, drag files to the drop-box or click to browse local files. The dataset is required to include the columns, with corresponding formats, found in the table below. </Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <DataUpload reciveData={callbackDataUpload} reciveCoruptData={callbackCoruptData} />
                    </Grid.Col>
                    <Grid.Col span={8}>
                      <TableComponent data={
                        [
                          {
                            'COLUMN NAME': 'Timestamp',
                            FORMAT: 'date',
                            ROLE: 'Timepoint',
                          },
                          {
                            'COLUMN NAME': 'Facility_ID',
                            FORMAT: 'str',
                            ROLE: 'ID',
                          },
                          {
                            'COLUMN NAME': 'Customer_ID',
                            FORMAT: 'str',
                            ROLE: 'ID',
                          },
                          {
                            'COLUMN NAME': 'Stage',
                            FORMAT: 'float: {1.0, 2.0, 2.5, 3.0}',
                            ROLE: 'key figure',
                          },
                          {
                            'COLUMN NAME': 'ECL',
                            FORMAT: 'float',
                            ROLE: 'key figure',
                          },
                          {
                            'COLUMN NAME': 'PD12',
                            FORMAT: 'float',
                            ROLE: 'model output',
                          },
                          {
                            'COLUMN NAME': 'D12',
                            FORMAT: 'bool',
                            ROLE: 'model target',
                          },
                          {
                            'COLUMN NAME': '*Segment',
                            FORMAT: 'str',
                            ROLE: 'key for data subsets',
                          },
                          {
                            'COLUMN NAME': '*Defaulted',
                            FORMAT: 'bool',
                            ROLE: 'key figure',
                          },
                          {
                            'COLUMN NAME': '*Rating',
                            FORMAT: 'int',
                            ROLE: 'key figure',
                          },
                          {
                            'COLUMN NAME': '*Factor name 1',
                            FORMAT: 'float',
                            ROLE: 'model input factor',
                          },
                          {
                            'COLUMN NAME': '...',
                            FORMAT: '...',
                            ROLE: '...',
                          },
                          {
                            'COLUMN NAME': '*Factor name n',
                            FORMAT: 'float',
                            ROLE: 'model input factor',
                          },
                        ]
                      } />
                      <Text>*Optional</Text>
                    </Grid.Col>
                  </Grid>
                )}
              </Grid.Col>
              <Grid.Col span={12}>
                <Divider />
              </Grid.Col>
            </Grid>
          </Stepper.Step>

          <Stepper.Step icon={<IconSettings size={28} />} label="Settings">
            {dataUploaded ? (
              <Settings
                hasRiskFactors={hasRisk}
                data={settingsData}
                reciveSettings={callbackSettings}
                active={active}
              />
            ) : (
              <h2>Please upload data in the previous step...</h2>
            )}
          </Stepper.Step>

          <Stepper.Step icon={<IconReport size={28} />} label="Generate report">
            <ReportPreview
              data={filteredData}
              settings={settings}
              // reciveImg={callbackImg}
            />
          </Stepper.Step>

          <Stepper.Completed>
            Click the button below to download the generated report.
            <Report data={filteredData} settings={settings} />
          </Stepper.Completed>
        </Stepper>

        {active === 0 && dataUploaded ? (
          <Group position="center" mt="xl">
            <Button color="cr-red.4" onClick={prevStep && handleCancel}>
              Cancel
            </Button>
            <Button onClick={nextStep}>Next</Button>
          </Group>
        ) : active === 1 && dataUploaded ? (
          <Group position="center" mt="xl">
            <Button variant="default" onClick={prevStep}>
              Back
            </Button>
            <Button onClick={handleFilterData}>Next</Button>
          </Group>
        ) : active === 2 && dataUploaded ? (
          <Group position="center" mt="xl">
            <Button variant="default" onClick={prevStep}>
              Back
            </Button>
            <Button onClick={nextStep}>Generate Report</Button>
          </Group>
        ) : (
          <div></div>
        )}
      </Container>
    </div>
  );
};

export default Valhalla;
