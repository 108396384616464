import React from "react";
import { useRef, useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "../../api/axios";
import CRLoader from "../animations/CRLoader";
const LOGIN_URL = "/token";

const Login = () => {
  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/home";

  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const form = new FormData();
      form.append("username", user);
      form.append("password", pwd);
      form.append("client_id", "");
      form.append("client_secret", "");
      form.append("scope", "");
      form.append("grant_type", "password");

      const response = await axios.post(LOGIN_URL, form, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      });
      console.log(response);
      const accessToken = response?.data?.access_token;

      setAuth({ user, accessToken });
      setUser("");
      setPwd("");
      setLoading(false);
      navigate(from, { replace: true });
    } catch (err) {
      console.log(err)
      if (!err?.response || err.response == null) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missign Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      setLoading(false);
      // errRef.current.focus()
    }
  };

  return (
    <section className="page">

      {loading ? (
        <CRLoader size={25} />
      ) : (
        <form onSubmit={handleSubmit} className="inputs pt-3">
          <div className="input">
            <input
              type="email"
              id="username"
              ref={userRef}
              autoComplete="off"
              onChange={(e) => setUser(e.target.value)}
              value={user}
              required
              placeholder="Enter username"
            />
          </div>
          <div className="input">
            <input
              type="password"
              id="password"
              onChange={(e) => setPwd(e.target.value)}
              value={pwd}
              required
              placeholder="Enter password"
            />
          </div>
          <div className="button">
            <button type="submit">Log in</button>
          </div>
          {errMsg ? (
            <div ref={errRef} className="error" aria-live="assertive">
              {errMsg}
            </div>
          ) : null}
        </form>
      )}
    </section>
  );
};

export default Login;
