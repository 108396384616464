import React from 'react'

const Footer = () => {
  return (
    <div className='footer'>
      <div className='container'>
        <img className='footer-logo' src="/logo_neg.svg" alt="CR Consulting" />
        <div className='row'>
          <div className='col text-center'>
          </div>
          <div className='col'>
            <ul>
              <li>+45 3063 0714</li>
              <li>info@crconsulting.dk</li>
            </ul>
          </div>
          <div className='col'>
            <ul>
              <li>CR Consulting I/S</li>
              <li>CVR: 43429582</li>
            </ul>
          </div>
          <div className='col'>
            <ul>
              <li>Klerkegade 19 st. tv.</li>
              <li>1308 København K</li>
            </ul>
          </div>
        </div>
        <hr />
        <div className='row text-center'>
          <p className='col-sm'> &copy;{new Date().getFullYear()} CR Consulting I/S | All righs reserved | Privacy </p>
        </div>
      </div>
    </div>
  )
}

export default Footer