export const addOneMonth = (inputDate) => {
  const newDate = new Date(inputDate);
  newDate.setMonth(newDate.getMonth() + 1);
  return newDate;
};

export const subtractOneMonth = (inputDate) => {
  const newDate = new Date(inputDate);
  newDate.setMonth(newDate.getMonth() - 1);
  return newDate;
};

