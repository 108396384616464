import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import AuthContext from "../../context/AuthProvider";
import { nav } from "./navigation";
import { useContext } from "react";
import { getRoles } from "../RequireAuth";

// TODO: Implement this!!!!
// export const RenderRoutes = () => {

//   const { auth } = useAuth()

//   return (
//     <Routes>
//       {nav.map((r, i) => {

//         if (r.isPrivate && auth.user) {
//           return <Route key={i} path={r.path} element={r.element} />
//         } else if (!r.isPrivate) {
//           return <Route key={i} path={r.path} element={r.element} />
//         } else return false
//       })}

//     </Routes>
//   )
// }

export const RenderMenu = () => {
  const { setAuth } = useContext(AuthContext)
  const { auth } = useAuth()
  const navigate = useNavigate()
  const roles = getRoles(auth)

  const logout = () => {
    // if used in more components, this should be in context 
    // axios to /logout endpoint 
    setAuth({});
    navigate('/');
  }

  const MenuItem = ({ r }) => {
    return (
      <div className="menuItem"><Link to={r.path}>{r.name}</Link></div>
    )
  }
  return (
    <div className="menu">
      {nav.map((r, i) => {

        if (!r.isPrivate && r.isMenu) {
          return (
            <MenuItem key={i} r={r} />
          )
        } else if (roles.find(role => r.allowedRoles?.includes(role)) && r.isMenu) {
          return (
            <MenuItem key={i} r={r} />
          )
        } else return false
      })}

      {auth.user ?
        <div className="menuItem"><Link to={'/'} onClick={logout}>Log out</Link></div>
        :
        <div className="menuItem"><Link to={'/'}>Login</Link></div>}
    </div>
  )
}